<template>
	<footer>
		<div class="container">
			<div class="row">
				<div class="col-md-6 col-sm-12 col-lg-6 col-xs-12">
					<h4>Our Address</h4>
					<div class="footer-item">
						<p>East Legon - Adjiringanor, Accra, Ghana</p>
						<p>Around Ability Square, off Dzen Ayor Junction</p>
						<p>P.O. Box GP13539, Accra</p>
						<p>Phone Number: 233558414611</p>
						<p>Email: eastbridgeintertionalschool@gmail.com</p>
					</div>
				</div>
				<div class="col-md-6 col-sm-12 col-lg-6 col-xs-12">
					<h4>Contact Us</h4>
					<div class="footer-item footer-contact">
						<form @submit.prevent="sendContactInfo">
							<div class="row">
								<div class="col-md-6 col-sm-12 col-lg-6 col-xs-12">
									<div class="form-group">
										<input type="text" placeholder="Your Name" class="form-control" required>
									</div>
								</div>
								<div class="col-md-6 col-sm-12 col-lg-6 col-xs-12">
									<div class="form-group">
										<input type="text" placeholder="Email Address" class="form-control" required>
									</div>
								</div>
							</div>
							<div class="form-group">
								<textarea class="form-control" placeholder="Compose message here ..." required/>
							</div>
							<button type="submit" class="btn btn-block">Send</button>
						</form>
					</div>
				</div>
			</div>
		</div>
	</footer>
</template>


<script>
export default {
	data(){
		return {
			contact: {
				name: "",
				email: "",
				message: "",
				telephone: "",
			}
		}
	},
	methods: {
		sendContactInfo(){

		}
	}
}
</script>